/* eslint-disable no-shadow */
export interface Org {
  id: string;
  name: string;
  regno?: string | null;
  vatno?: string | null;
  alias: string;
  zip?: string | null;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  acceptedTermsPrivacy?: boolean | null;
  cloudAuthJwksUrl?: string | null;
  cloudAuthJwksIssuer?: string | null;
  createdAt: string;
  updatedAt: string;
}

export enum PropertyFeatureFlags {
  // issues & tasks
  TICKETS = 'tickets',
  TASKS = 'tasks',
  SMART_DOCS = 'smart_docs',
  // smart metering and sensors
  SMART_METERING = 'smart_metering',
  SENSORS = 'sensors',
  ENERGY_PRICES = 'energy_prices',
  WEATHER_FORECAST = 'weather_forecast',
  // access
  APP_USERS = 'app_users',
  ACCESS_CARDS = 'access_cards',
  PIN_USERS = 'pin_users',
  LICENSE_PLATES = 'license_plates',
  SMS_CODES = 'sms_codes',
  EMAIL_CODES = 'email_codes',
  SMART_UNITS = 'smart_units',
  // systems management
  TECHNICAL_SYSTEMS = 'technical_systems',
}

export const FeatureFlagNames = {
  [PropertyFeatureFlags.TICKETS]: 'Maintenance Requests',
  [PropertyFeatureFlags.TASKS]: 'Maintenance Tasks',
  [PropertyFeatureFlags.SMART_DOCS]: 'Property Smart Documents store',
  [PropertyFeatureFlags.SMART_METERING]: 'Smart Metering',
  [PropertyFeatureFlags.ENERGY_PRICES]: 'Energy Prices Integration',
  [PropertyFeatureFlags.WEATHER_FORECAST]: 'Weather Forecast Integration',
  [PropertyFeatureFlags.SENSORS]: 'Sensors Visualization and Smart Alarms',
  [PropertyFeatureFlags.APP_USERS]: 'Access Group App Users Management',
  [PropertyFeatureFlags.ACCESS_CARDS]: 'Access Group Cards Management',
  [PropertyFeatureFlags.PIN_USERS]: 'Access PIN Users Management',
  [PropertyFeatureFlags.LICENSE_PLATES]: 'Access License Plates',
  [PropertyFeatureFlags.SMS_CODES]: 'Sending SMS notifications (eg. access codes, invitations)',
  [PropertyFeatureFlags.EMAIL_CODES]: 'Sending Email notifications (eg. codes)',
  [PropertyFeatureFlags.SMART_UNITS]: 'Smart Units',
  [PropertyFeatureFlags.TECHNICAL_SYSTEMS]: 'Technical Systems',
};

export enum RoleType {
  SYSTEM = 'systemRole',
  ORG = 'orgRole',
  PROPERTY = 'propertyRole',
  ACCESS_POLICY = 'accessPolicy',
  EXTERNAL_PARTNER = 'externalPartnerRole'
}

export enum AccessPolicyRole {
  USER = 'user',
  MASTER = 'master',
}

export enum OrgRolePermission {
  MANAGER = 'manager',
  ADMIN = 'admin'
}

export enum PropertyRolePermission {
  PENDING = 'pending',
  MANAGER = 'manager',
  ADMIN = 'admin'
}
export enum SystemRolePermission {
  MANAGER = 'manager',
  ADMIN = 'admin'
}
export enum ExternalPartnerRole {
  ANY = 'any'
}

export interface MeAccessGroupRole {
  id: string;
  accessPolicyRole: AccessPolicyRole;
}
export interface MeExternalPartnerRole {
  id: string;
}

export interface MeOrgRole {
  id: string;
  role: OrgRolePermission;
}

export interface MeSystemRole {
  id: string;
  role: SystemRolePermission
}

export interface MePropertyRole {
  id: string;
  role: PropertyRolePermission
}

export interface MePropertyData {
  id: string;
  accessGroupRoles: MeAccessGroupRole[];
  externalPartnerRoles: MeExternalPartnerRole[];
  propertyRoles: MePropertyRole[];
}
export type MeOrgData = Org & {
  orgRoles: MeOrgRole[];
  properties: MePropertyData[];
};

export interface MeData {
  orgs: MeOrgData[];
  systemRole?: MeSystemRole;
}
export enum PropertyPrivacyLevel {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export const PropertyPrivacyLevelValues = [
  { value: PropertyPrivacyLevel.PUBLIC, text: 'Organization-wide', description: 'Any manager can join and manage the property.' },
  { value: PropertyPrivacyLevel.PRIVATE, text: 'Admin-controlled', description: 'Management of the property is restricted to admin-approved individuals.' },
];

export interface Property {
  id: string;
  name: string;
  alias: string;
  orgId: string;
  line1: null | string;
  line2: null | string;
  postalCode: null | string;
  countryCode: null | string;
  countrySubdivisionCode: null | string;
  latitude: null | string;
  longitude: null | string;
  timezone: null | string;
  privacyLevel: PropertyPrivacyLevel;
  featureFlags: null | PropertyFeatureFlags[];
}

export type CurrentOrg = Org;

// eslint-disable-next-line no-shadow
export enum Roles {
  PROPERTY_ROLE = 1,
  ORG_ROLE = 2,
  ACCESS_GROUP = 3,
  EXTERNAL_PARTNER = 4,
}
